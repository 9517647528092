import React, {useState} from 'react';
import {DuplicateTableRow} from 'components/DuplicateTableRow';
import {Button} from 'components/Button';
import {Modal} from 'components/Modal';

import styles from 'css_modules/DuplicateModal.module.css';


export const DuplicatesDisplayModal = ({duplicates,  close, clickedOk}) => {
    const [ setMarked] = useState([]);
    const handleCheck = (e, id)=>{
        if(e.currentTarget.checked){
            setMarked(prev=>[...prev, id]);
        }else{
            setMarked(prev=>prev.filter(m=>m !== id));
        }
    }

    let duplicatesToShow = []
    for (let int = 0; int<duplicates.length;int++){
        if (duplicates[int].duplicates.length>0){
            duplicatesToShow.push(duplicates[int])
        }
    }       

    if (duplicates.length===0){
        clickedOk()
    }
    else{
    return (
        <div className={styles.ontop}>
        <Modal name="Duplicitní záznamy" close={close}>
            <main className={styles.body}>
            {duplicatesToShow.map((duplicate, i) =>
                <div className={styles.block} key={duplicate.document + (""+ i)}>
                    <p>{`Vyplněné údaje jsou shodné s ${duplicate.duplicates.length > 1 ? 'jinými doklady' : 'jiným dokladem'}:`}</p>
                    <table className={styles.table}>
                        <thead>
                        <tr>
                            <th>
                                ID
                            </th>
                            <th>
                                Stav
                            </th>
                            <th>Vytvořil</th>
                        </tr>
                        </thead>
                        <tbody>
                        {duplicate.duplicates.map(duplicate => <DuplicateTableRow key={duplicate.uuid} duplicate={duplicate}/>)}
                        </tbody>
                    </table>
                    {duplicates.length > 1 &&
                    <input className={styles.checkbox} onChange={(e)=>handleCheck(e, duplicate.document)} type="checkbox" id={"doc"+i} value={i + " checked"}/>
                    }
                    <p className={styles.pShort}>Chcete přesto odeslat tento doklad?</p>
            </div>

            )}

        </main>
            <footer className={styles.buttons}>
                <Button onClick={close}>Zrušit</Button>
                <Button onClick={clickedOk} primary>Pokračovat</Button>
            </footer>
        </Modal>
        </div>
    )}
};